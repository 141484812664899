import React, { FC, useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useGesture } from 'react-use-gesture';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as EpisodesIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_episodes.svg';
import { DrawerCommonProps } from '../../VideoTypes';
import SelectorDrawer from './SelectorDrawer';

import styles from './styles.module.scss';

export interface Props extends DrawerCommonProps {
  isShow?: boolean;
  isDisabled?: boolean;
  isSport?: boolean;
  onActiveChange: (active: boolean) => void;
  isLivePlayer?: boolean;
}

const ResourcePanel: FC<Props> = ({
  isShow = false,
  isDisabled = false,
  isSport = false,
  onActiveChange,
  isLivePlayer = false,
  drawerTabs,
  drawerContentList,
  isDrawerContentLoading,
  selectedTabId,
  selectedContentId,
  onDrawerTabChange,
  onDrawerContentSelect,
}) => {
  const [isDrawerHovering, setIsDrawerHovering] = useState(false);

  const setIsDrawerHoveringDebounce = useCallback(debounce(setIsDrawerHovering, 400), []);

  const bindDrawerHover = useGesture({
    onHover: ({ hovering }) => {
      if (hovering) {
        setIsDrawerHoveringDebounce.cancel();
        setIsDrawerHovering(true);
      } else {
        setIsDrawerHoveringDebounce(false);
      }
    },
  });

  let buttonTitle = '';
  if (isSport) {
    buttonTitle = 'View More';
  } else {
    buttonTitle = isLivePlayer ? 'Channels' : 'Seasons & Episodes';
  }

  useEffect(() => {
    return () => {
      setIsDrawerHoveringDebounce.cancel();
    };
  }, [setIsDrawerHoveringDebounce]);

  useEffect(() => {
    onActiveChange(isDrawerHovering);
  }, [isDrawerHovering, onActiveChange]);

  return (
    <div className={classnames(styles.resourcePanel, { [styles.hide]: !isShow })}>
      <div
        className={classnames(styles.drawerPanel)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindDrawerHover()}
      >
        <button
          type="button"
          className={classnames(styles.controlButton, styles.drawerBtn)}
          title={buttonTitle}
          data-testid="video-player-resources-drawer-button"
          name="Player resources drawer button"
        >
          <Icon className={styles.buttonIcon} component={EpisodesIcon} />
          <span>{buttonTitle}</span>
        </button>
        <SelectorDrawer
          isShow={!isDisabled && isDrawerHovering}
          isLivePlayer={isLivePlayer}
          drawerTabs={drawerTabs}
          drawerContentList={drawerContentList}
          isDrawerContentLoading={isDrawerContentLoading}
          selectedTabId={selectedTabId}
          selectedContentId={selectedContentId}
          onDrawerTabChange={onDrawerTabChange}
          onDrawerContentSelect={onDrawerContentSelect}
        />
      </div>
    </div>
  );
};

export default ResourcePanel;
