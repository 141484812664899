import React, { FC, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';

import { PlaybackMeta } from '@skytvnz/sky-app-store/lib/types/models/PlaybackMeta';

import usePersistCallback from '@/Hooks/usePersistCallback';
import Preloader from '@/Layouts/containers/Preloader';

import { youboraPlugin } from '@/Components/VideoPlayer/Core/YouboraPlayerConfig';
import youbora from 'youboralib';
import 'youbora-adapter-shaka';

import { PlayerProps, PlayerController } from './PlayerTypes';
import ShakaControllerFactory from './ShakaControllerFactory';
import MetaLoader, { createResolve } from './MetaLoader';

const PLAYER_PLAYING = 'vjs-playing';
const PLAYER_PAUSED = 'vjs-paused';
const PLAYER_WAITING = 'vjs-waiting';

let videoInstanceId = 0;

const generateVideoId = () => {
  videoInstanceId += 1;
  return `vjs_video_${videoInstanceId}`;
};

const ShakaPlayer: FC<PlayerProps> = ({
  playbackMeta,
  config,
  autoPlay,
  className,
  width,
  height,
  youboraPlayerConfig,
  startPosition,
  ...events
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<any>();
  const playerControllerRef = useRef<PlayerController>();
  const eventManagerRef = useRef<any>();
  const sourceResolve = useRef(createResolve<PlaybackMeta>());

  const [videoId, setVideoId] = useState('');
  const [isBuffering, setIsBuffering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const initPlayerEvents = usePersistCallback(shaka => {
    const eventManager = new shaka.util.EventManager();
    const {
      onError,
      onEnded,
      onDataLoaded,
      onPlay,
      onPause,
      onBuffering,
      onTimeUpdate,
      onWaiting,
      onPlaying,
      onSeeking,
      onSeeked,
    } = events;

    const { current: player } = playerRef;
    const { current: video } = videoRef;

    eventManager.listen(player, 'buffering', event => {
      setIsBuffering(event.buffering);
      if (!event.buffering) {
        onBuffering?.();
      }
    });
    eventManager.listen(player, 'loading', () => {
      setIsLoading(true);
    });
    eventManager.listen(player, 'loaded', () => {});
    eventManager.listen(player, 'error', onError);
    eventManager.listen(video, 'ended', () => onEnded?.());
    eventManager.listen(video, 'play', () => {
      setIsPlaying(true);
      onPlay?.();
    });
    eventManager.listen(video, 'loadeddata', () => {
      onDataLoaded?.();
      setIsLoading(false);
    });
    eventManager.listen(video, 'pause', () => {
      setIsPlaying(false);
      onPause?.();
    });
    eventManager.listen(video, 'playing', () => {
      onPlaying?.();
      setIsLoading(false);
    });
    eventManager.listen(video, 'timeupdate', () => onTimeUpdate?.());
    eventManager.listen(video, 'waiting', () => {
      setIsLoading(true);
      onWaiting?.();
    });
    eventManager.listen(video, 'seeking', onSeeking);
    eventManager.listen(video, 'seeked', onSeeked);

    eventManagerRef.current = eventManager;
  });

  // Effect to handle component mount & mount. creates a shaka.Player instance.
  useEffect(() => {
    // Dynamic import the shaka player script from sky CDN
    const playerLoader = (async () => {
      const shaka = await import('shaka-player');

      if (videoRef.current) {
        setVideoId(generateVideoId());
        playerRef.current = new shaka.Player(videoRef.current);

        playerControllerRef.current = ShakaControllerFactory(
          playerRef.current,
          videoRef.current,
          autoPlay,
        );

        initPlayerEvents(shaka);

        events?.onPlayerLoaded?.(playerControllerRef.current);
      }
    })();

    // Waiting until player script and sources all loaded
    (async () => {
      const pendedMeta = await MetaLoader(playerLoader, sourceResolve.current);
      playerControllerRef.current?.loadMeta?.(pendedMeta, startPosition);
    })();

    return () => {
      playerRef.current?.destroy();
      playerRef.current = null;
      eventManagerRef.current?.release();
      events?.onPlayerUnload?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPosition]);

  useEffect(() => {
    if (playerRef.current && youboraPlayerConfig && youboraPlugin && playbackMeta) {
      youboraPlugin.setAdapter(new youbora.adapters.Shaka(playerRef.current));
      youboraPlugin.setOptions(youboraPlayerConfig?.getOptions());
    }
  }, [youboraPlayerConfig, playbackMeta]);

  // Keep shaka.Player.configure in sync.
  useEffect(() => {
    const { current: player } = playerRef;
    if (player && config) {
      player.configure(config);
    }
  }, [config]);

  // Load the source url when we have one.
  useEffect(() => {
    if (!playbackMeta || !playbackMeta.playbackSource) return;

    if (playerRef.current) {
      // If player has been initialized
      playerControllerRef.current?.loadMeta?.(playbackMeta, startPosition);
    } else {
      // If player still loading, add into async racing
      sourceResolve.current?.load(playbackMeta);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      youboraPlugin.removeAdapter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackMeta, startPosition]);

  return (
    <>
      <Preloader isLoading={isLoading || isBuffering} />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        id={videoId}
        data-testid="shaka-player"
        className={classnames(className, {
          [PLAYER_PLAYING]: isPlaying,
          [PLAYER_PAUSED]: !isPlaying,
          [PLAYER_WAITING]: isLoading || isBuffering,
        })}
        style={{ maxWidth: '100%' }}
        ref={videoRef}
        width={width}
        height={height}
      />
    </>
  );
};

export default ShakaPlayer;
