import React, { FC, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { isNil } from 'ramda';
import dayjs from 'dayjs';
import { Button } from 'antd';

import { ReactComponent as Close } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_close.svg';
import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { LinearChannel } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { formatGenres, getGenres } from '@/Utils/Genres';
import { formatRating } from '@/Utils/Rating';
import SlotProgress from '@/Components/SlotProgress/SlotProgress';
import ErrorMessage from '@/Components/Message';
import { utils, hooks } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import { tvGuideMoreEpisodesSelected } from '@/Analytics/Segment';

import RecordButton from '@/Components/RecordButton';
import MoreEpisodesButton from '@/Components/Button/MoreEpisodesButton';
import { useChannelSubscriptions } from '@/Hooks/useSubscription';
import buttonStyle from '@/Components/Button/styles.module.scss';
import useLivePlayer from '@/Hooks/useLivePlayer';
import { getViewPage } from '@/Utils/PageLocation';
import { getVideoImg } from '@/Utils/VideoImage';
import LiveTag from '@/Components/LiveTag';

import styles from './styles.module.scss';

interface SlotDetailsProps {
  slotStartTime?: string;
  channel?: LinearChannel;
  onClose: any;
  history: any;
  selectedChannelGroup?: string;
  weekday?: string;
  daysFromToday?: number;
}

const NUMBER_OF_GENRES_TO_DISPLAY = 1;

const SlotDetails: FC<SlotDetailsProps> = ({
  slotStartTime,
  onClose,
  channel,
  history,
  selectedChannelGroup,
  weekday,
  daysFromToday,
}) => {
  const modalElement = useRef<HTMLDivElement>(null);
  const {
    title,
    image,
    synopsis,
    isOnAir,
    isShow,
    isSport,
    isLoading,
    isLoadingError,
    brand,
    slot,
  } = hooks.useSlotDetailsData({ slotStartTime, channelId: channel?.id });

  // Brand level information
  const genres = getGenres(formatGenres(brand?.primaryGenres), NUMBER_OF_GENRES_TO_DISPLAY);
  const start = dayjs(slot?.start).format('ddd, D MMM, h:mm a');
  const end = dayjs(slot?.end).format('h:mm a');
  const channelNumber = `Channel ${channel?.number}`;
  const rating = slot?.rating && formatRating(slot?.rating);

  const { openAndPlay } = useLivePlayer();
  const requiredSubscriptions = useChannelSubscriptions()(channel?.id);

  const onWatch = () => {
    if (isOnAir && channel) {
      // Slot Modal is not in the Router wrapper component, so can not use the
      // 'react-router-dom' history from React Context
      openAndPlay(
        channel,
        slot,
        selectedChannelGroup,
        0,
        history,
        undefined,
        'TV Guide Program View',
      );
    }
  };

  const onMoreEpisodes = () => {
    if (isShow && brand?.id) {
      tvGuideMoreEpisodesSelected({
        content_id: brand?.id,
        content_title: utils.analytics.getSegmentSlotTitle(slot),
      });
      history.push(`/show/${brand.id}`);
    }
  };

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [modalElement, onClose]);

  return (
    <Preloader isLoading={isLoading || (isNil(brand) && !isLoadingError)}>
      <div className={styles.slotDetailsContainer} ref={modalElement} data-testid="slot-details">
        <div className={styles.slotDetails}>
          <Close className={styles.closeIcon} onClick={onClose} />
          {isLoadingError ? (
            <ErrorMessage className={styles.errorMsg} buttonText="Close" onButtonClick={onClose} />
          ) : (
            <>
              <div className={styles.poster}>
                <img
                  src={getVideoImg(ImageAssetType.ContentTileVertical, image)}
                  alt={`The poster of "${title}"`}
                />
              </div>
              <div className={styles.slotMeta}>
                <span className={styles.title} data-testid="slot-detail-title">
                  {title}
                  {slot?.live && <LiveTag className={styles.liveTag} />}
                </span>
                <div className={styles.attributes}>
                  {channel?.title && (
                    <span className={styles.delimiter} data-testid="slot-details-channel-title">
                      {channel?.title}
                    </span>
                  )}
                  {channelNumber && (
                    <span className={styles.delimiter} data-testid="slot-details-channel-number">
                      {channelNumber}
                    </span>
                  )}
                  {genres && (
                    <span className={styles.delimiter} data-testid="slot-details-genres">
                      {genres}
                    </span>
                  )}
                  {start && end && (
                    <span className={styles.delimiter} data-testid="slot-details-time-range">
                      {start} - {end}
                    </span>
                  )}
                  {rating && (
                    <span className={styles.delimiter} data-testid="slot-details-rating">
                      {rating}
                    </span>
                  )}
                </div>
                {synopsis && (
                  <div className={styles.synopsis} data-testid="slot-details-synopsis">
                    {synopsis}
                  </div>
                )}
                {isOnAir && <SlotProgress slotStart={slot?.start} slotEnd={slot?.end} />}
                <div className={styles.controls}>
                  {isOnAir && (
                    <Button
                      type="primary"
                      className={classnames(buttonStyle.controlButton, buttonStyle.primary)}
                      onClick={onWatch}
                      data-testid="slot-details-watch-button"
                      name="Slot details watch button"
                    >
                      <Play className={buttonStyle.controlButtonIcon} />
                      <span className={buttonStyle.controlButtonSpan}>
                        {requiredSubscriptions ? 'Upgrade to Watch' : 'Watch'}
                      </span>
                    </Button>
                  )}
                  <RecordButton
                    slot={slot || null}
                    channel={channel || null}
                    requiredSubscriptions={requiredSubscriptions}
                    subscriptionAnalyticParams={{
                      categoryName: selectedChannelGroup,
                      contentId: slot?.programme?.id || '',
                      contentTitle: utils.analytics.getSegmentSlotTitle(slot),
                      contentType: utils.analytics.getContentTypeBySlot(slot),
                      channelName: utils.analytics.getSegmentSlotChannel(channel || null),
                      viewName: getViewPage(history),
                    }}
                    remoteRecordAnalyticParams={{
                      categoryName: selectedChannelGroup,
                      channelName: channel?.title,
                      contentTitle: utils.analytics.getSegmentSlotTitle(slot),
                      genres: utils.analytics.getSegmentSlotGenres(slot),
                      history,
                      weekday,
                      daysFromToday,
                      contentType: utils.analytics.getContentTypeBySlot(slot),
                    }}
                  />
                  {isShow && <MoreEpisodesButton onClick={onMoreEpisodes} isSport={isSport} />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Preloader>
  );
};

export default SlotDetails;
