import React, { FC } from 'react';
import classnames from 'classnames';
import { isNil } from 'ramda';

import { LiveStatus } from '@skytvnz/sky-app-store/lib/types/enums/LiveStatus';
import { LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { utils } from '@/Store';

import styles from './styles.module.scss';

export interface LiveTagProps {
  liveStatus?: LiveStatus;
  testId?: string;
  className?: string;
  slot?: LinearSlot;
}
const LiveTag: FC<LiveTagProps> = ({ className, testId, slot }) => {
  // if slot is nil we default to LiveStatus.LIVE
  const computedliveStatus = !isNil(slot)
    ? utils.slot.getSportLiveStatusByTime(slot, process.env.ENV === 'PRODUCTION')
    : LiveStatus.LIVE;

  return computedliveStatus === LiveStatus.NOT_LIVE ? null : (
    <span data-testid={testId || 'slot-live-tag'} className={classnames(styles.liveTag, className)}>
      {computedliveStatus === LiveStatus.LIVE ? 'LIVE' : 'LIVE SOON'}
    </span>
  );
};

export default LiveTag;
