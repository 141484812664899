import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';

import { LinearChannelGroup } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import Content from '@/Layouts/containers/Content';
import DropDown from '@/Components/DropDown/DropDown';
import ErrorMessage from '@/Components/Message';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import usePersistCallback from '@/Hooks/usePersistCallback';
import useIsLoaded from '@/Hooks/useIsLoaded';
import { actions, selectors } from '@/Store';
import DateSelector from '@/Pages/TVGuide/DateSelector/DateSelector';

import { tvGuideChannelGroupSelected, tvGuideWeekdaySelected } from '@/Analytics/Segment';
import pageViewedAnalytics from '@/Analytics/Page';
import dayjs from 'dayjs';
import { createGlobalState } from 'react-use';
import { isEmpty } from 'ramda';
import SlotsGrid from './SlotsGrid';
import { FUTURE_DATE_PRIMETIME } from './constants';

import styles from './styles.module.scss';

export const incrementDate = (startDate: dayjs.Dayjs, days: number) => {
  return startDate.add(days, 'day').hour(FUTURE_DATE_PRIMETIME).minute(0).second(0).millisecond(0);
};

const groupToOption = (group: LinearChannelGroup) => {
  return { label: group.title, value: group.id };
};

const today = dayjs();

const dateList = [
  today,
  incrementDate(today, 1),
  incrementDate(today, 2),
  incrementDate(today, 3),
  incrementDate(today, 4),
  incrementDate(today, 5),
  incrementDate(today, 6),
];

export const useGlobalDateFilter = createGlobalState<dayjs.Dayjs>(dateList[0]);
export const useGlobalFirstStartTime = createGlobalState<dayjs.Dayjs>(dateList[0]);

const TVGuide: React.FC = () => {
  usePageHeader({
    title: 'TV Guide',
    description: 'Browse the TV Guide to find out what’s on and remote record to your My Sky.',
  });
  const groups = useSelector(selectors.channels.categories);
  const isGroupsLoading = useSelector(selectors.channels.categoriesIsLoading);
  const isGroupsLoaded = useIsLoaded(isGroupsLoading);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useGlobalDateFilter();
  const [firstStartTime, setFirstStartTime] = useGlobalFirstStartTime();
  const selectedCategory = useSelector(selectors.channels.selectedCategory);

  useEffect(() => {
    pageViewedAnalytics('TV Guide');
    // Load all the channels icon image only but not the EPG & Schedule
    dispatch(actions.channels.fetchCategories(false, true));
  }, [dispatch]);

  const selectedChannelGroupChanged = usePersistCallback(selectedOption => {
    const selectedGroup = groups.find(group => group.id === selectedOption.value);
    if (selectedGroup) {
      dispatch(actions.channels.setSelectedCategoryId(selectedGroup.id));
      tvGuideChannelGroupSelected({ category_name: selectedGroup.title });
    }
  });

  // Once categories be loaded in first time
  useEffect(() => {
    // select first one as default selection category
    if (!selectedCategory) {
      dispatch(actions.channels.setSelectedCategoryId(groups?.[0]?.id || ''));
    }
  }, [groups, selectedCategory, dispatch]);

  const options = useMemo(() => groups.map(item => groupToOption(item)), [groups]);

  const selectDate = usePersistCallback(date => {
    setSelectedDate(date);
    setFirstStartTime(date);

    tvGuideWeekdaySelected({
      days_from_today: dateList.indexOf(date),
      weekday_name: date.format('dddd'),
      method: 'select',
    });
  });

  const onDateShift = usePersistCallback((newInitialTime, dateShift) => {
    const newDate = incrementDate(firstStartTime as dayjs.Dayjs, dateShift);
    setSelectedDate(newDate);
    setFirstStartTime(newInitialTime);

    tvGuideWeekdaySelected({
      days_from_today: newDate.diff(new Date(), 'day'),
      weekday_name: newDate.format('dddd'),
      method: 'scroll',
    });
  });

  return isGroupsLoaded && isEmpty(groups) ? (
    <ErrorMessage
      buttonText="Reload"
      onButtonClick={() => {
        window.location.reload();
      }}
    />
  ) : (
    <>
      <Content>
        <div className={styles.tvGuideHeaderSection}>
          <h1 className={styles.title} data-testid="tv-guide-title">
            TV Guide
          </h1>
          <DropDown
            className={styles.groupSelector}
            options={options}
            selectedValue={selectedCategory.id}
            onChange={selectedChannelGroupChanged}
          />
        </div>
        <DateSelector
          dateList={dateList}
          selectedDate={selectedDate as dayjs.Dayjs}
          onClick={selectDate}
        />
      </Content>
      {selectedCategory?.id && (
        <SlotsGrid
          categoryId={selectedCategory.id}
          firstStartTime={firstStartTime as dayjs.Dayjs}
          onDateShift={onDateShift}
          selectedChannelGroup={selectedCategory.title}
          weekday={selectedDate?.format('dddd')}
          daysFromToday={selectedDate?.diff(new Date(), 'day')}
        />
      )}
    </>
  );
};

export default withTransaction('TVGuide', 'component')(TVGuide);
