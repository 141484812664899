import React, { FC, useMemo, useEffect, useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { LinearChannel, LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils } from '@/Store';

import getTimeRange from '@/Utils/TimeFormat';
import LiveTag from '@/Components/LiveTag';
import styleVars from '@/brandedModules/skyGo/Styles/variables';
import { Tooltip } from 'antd';
import { useTimeWidthUnit } from '../useTimeWidthUnit';
import { CELL_MARGIN, MINUTES_PER_UNIT } from '../../constants';

import styles from './styles.module.scss';

interface SlotProps {
  slot: LinearSlot;
  firstStartTime: dayjs.Dayjs;
  channel: LinearChannel;
  scrollOffset: number;
  onClick: any;
  currentTime: dayjs.Dayjs;
}

const SlotCell: FC<SlotProps> = ({
  firstStartTime,
  scrollOffset,
  slot,
  channel,
  onClick,
  currentTime,
}) => {
  const { start, end, live, programme } = slot;

  const [offsetXY, setOffsetXY] = useState([0, 0]);
  const cellRef = useRef<HTMLDivElement>(null);
  const cellTitleRef = useRef<HTMLSpanElement>(null);
  const slotCellRef = useRef<HTMLDivElement>(null);
  const [slotBackground, setSlotBackground] = useState(styleVars.colorPalettePurpleDark);
  const [maskWidth, setMaskWidth] = useState<string>('0px');
  const [isTitleOverflowed, setTitleOverflowed] = useState(false);

  const widthUnit = useTimeWidthUnit();

  const onSlotClick = useCallback(() => {
    if (programme) {
      onClick(slot, channel);
    }
  }, [slot, channel, programme, onClick]);

  // Cell left according to the start point of the Grid
  const left = useMemo(() => {
    const startDurationInSeconds = dayjs(start).diff(firstStartTime, 'second');
    return widthUnit * (startDurationInSeconds / 60 / MINUTES_PER_UNIT);
  }, [start, firstStartTime, widthUnit]);

  // Cell width according to the time unit of the Grid
  const width = useMemo(() => {
    const slotDurationInSeconds = dayjs(end).diff(dayjs(start), 'second');
    return widthUnit * (slotDurationInSeconds / 60 / MINUTES_PER_UNIT) - CELL_MARGIN;
  }, [start, end, widthUnit]);

  // Slot background color split based on the start time
  const loadSlotBackground = useCallback(() => {
    const passedDurationInSeconds = currentTime.diff(dayjs(start), 'second');

    // Calculate hightlighted width according to the start time and current time
    const passedTimeOffset =
      widthUnit * (passedDurationInSeconds / 60 / MINUTES_PER_UNIT) - CELL_MARGIN;

    // calculate highlighted background ratio
    const highlightedField =
      passedTimeOffset < 0 && passedTimeOffset < width ? `0%` : `${passedTimeOffset}px`;

    if (passedTimeOffset > 0) {
      setSlotBackground(
        `linear-gradient(90deg, #393963 ${highlightedField} , ${styleVars.colorPalettePurpleDark} 0%)`,
      );
      setMaskWidth(highlightedField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, start, width, widthUnit]);

  // Scrolling left alignment
  useEffect(() => {
    const newMarginLeft =
      left < scrollOffset && left + width > scrollOffset ? scrollOffset - left : 0;

    // Direct mutate the dom style, better performance
    if (cellRef.current?.style) {
      cellRef.current.style.marginLeft = `${newMarginLeft}px`;
    }
    loadSlotBackground();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOffset, left, width]);

  const title = utils.slot.getSlotTitle(slot, true);

  useEffect(() => {
    loadSlotBackground();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    if (cellTitleRef.current?.offsetWidth) {
      if (cellTitleRef.current?.offsetWidth < cellTitleRef.current?.scrollWidth) {
        setTitleOverflowed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellTitleRef.current?.scrollWidth]);

  // set mouse hover mask width using css variable
  const setHoverMaskWidth = () => {
    slotCellRef.current?.style.setProperty('--hover-mask-width', maskWidth);
  };

  return (
    <div
      className={styles.slotCell}
      style={{
        width,
        transform: `translate3d(${left}px, 0, 0)`,
        background: slotBackground,
      }}
      role="button"
      tabIndex={0}
      onClick={onSlotClick}
      onKeyPress={onSlotClick}
      onMouseMove={e => {
        setOffsetXY([e.nativeEvent.offsetX + 10, e.nativeEvent.offsetY]);
      }}
      onMouseOver={setHoverMaskWidth}
      onFocus={() => {}}
      ref={slotCellRef}
    >
      <Tooltip
        title={isTitleOverflowed ? title : ''}
        align={{
          points: ['tl', 'tl'],
          offset: offsetXY,
        }}
        overlayClassName={styles.tooltip}
        mouseEnterDelay={1.5}
      >
        <div className={styles.content} ref={cellRef}>
          <span className={styles.title} data-testid="slot-cell-title" ref={cellTitleRef}>
            {programme && live && <LiveTag className={styles.liveTag} />}
            {title}
          </span>
          {programme && (
            <span className={styles.time} data-testid="slot-cell-time">
              {getTimeRange(start, end)}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default SlotCell;
