import React, { FC, useRef, useEffect } from 'react';
import brightCovePlayerLoader from '@brightcove/player-loader';
import { F } from 'ramda';
import { useSelector } from 'react-redux';
import { PlaybackMeta } from '@skytvnz/sky-app-store/lib/types/models/PlaybackMeta';

import usePersistCallback from '@/Hooks/usePersistCallback';
import { selectors } from '@/Store';
import { youboraPlugin } from '@/Components/VideoPlayer/Core/YouboraPlayerConfig';

import { PlayerProps, PlayerController } from './PlayerTypes';
import BrightCoveControllerFactory from './BrightCoveControllerFactory';
import MetaLoader, { createResolve } from './MetaLoader';
import YouboraVideoJsAdapter from './YouboraVideoJsAdapter';

export const BRIGHTCOVE_ACCOUNT_ID = process.env.BRIGHTCOVE_ACCOUNT_ID || '';
export const BRIGHTCOVE_PLAYER_ID = process.env.BRIGHTCOVE_PLAYER_ID || '';

export const PLAYER_USER_INACTIVE_TIMEOUT = 5000; // in ms
export const PLAYER_DURATION_JUMP = 10; // in seconds
export const PLAYER_VOLUME_DEFAULT = 1;
export const PLAYER_VOLUME_UNMUTE = 0.05;
export const PLAYER_VOLUME_JUMP = 0.05;

const videojsOptions = {
  autoplay: false,
  userActions: {
    hotkeys: {
      // Disable the VideoJS default shortcut events to use our own customize shortcut
      muteKey: F,
      fullscreenKey: F,
      playPauseKey: F,
    },
  },
  inactivityTimeout: PLAYER_USER_INACTIVE_TIMEOUT,
};

window.Plugin = youboraPlugin;

const BrightCovePlayer: FC<PlayerProps> = ({
  playbackMeta,
  config,
  isLivePlayer,
  className,
  width,
  height,
  youboraPlayerConfig,
  startPosition,
  ...events
}) => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any>();
  const playerControllerRef = useRef<PlayerController>();
  const playbackMetaResolve = useRef(createResolve<PlaybackMeta>());
  const accessToken = useSelector(selectors.auth.accessToken);

  const {
    onError,
    onEnded,
    onDataLoaded,
    onPlay,
    onPause,
    onBuffering,
    onTimeUpdate,
    onWaiting,
    onPlaying,
    onSeeking,
    onSeeked,
  } = events;

  /* Player events init */
  const initPlayerEvents = usePersistCallback(() => {
    const { current: player } = playerRef;
    player.on('loadeddata', onDataLoaded);
    player.on('ended', onEnded);
    player.on('play', onPlay);
    player.on('pause', onPause);
    player.on('waiting', onWaiting);
    player.on('playing', onPlaying);
    player.on('timeupdate', onTimeUpdate);
    player.on('progress', onBuffering);
    player.on('seeking', onSeeking);
    player.on('seeked', onSeeked);
    player.on('error', onError);
  });

  /* Player events removal */
  const disposeEvents = usePersistCallback(() => {
    const { current: player } = playerRef;
    if (player) {
      player.off('loadeddata', onDataLoaded);
      player.off('ended', onEnded);
      player.off('play', onPlay);
      player.off('pause', onPause);
      player.off('waiting', onWaiting);
      player.off('playing', onPlaying);
      player.off('timeupdate', onTimeUpdate);
      player.off('progress', onBuffering);
      player.off('seeking', onSeeking);
      player.off('seeked', onSeeked);
      player.off('error', onError);
    }
  });

  /**
   * Effect to handle component mount & mount. creates a shaka.Player instance.
   * As BC loader is dynamic loading the brightCove player, and in the meantime the playbackMeta is potential loading
   * So need to wait all async process to be done.
   */
  useEffect(() => {
    // Dynamic loading the player script from brightCove CDN
    const playerLoader = (async () => {
      const { ref: player } = await brightCovePlayerLoader({
        refNode: videoWrapperRef.current,
        accountId: BRIGHTCOVE_ACCOUNT_ID,
        playerId: BRIGHTCOVE_PLAYER_ID,
        embedId: 'default',
        options: videojsOptions,
      });

      playerRef.current = player;
      youboraPlugin.setAdapter(new YouboraVideoJsAdapter(playerRef.current));

      // Focus to the player, so that user can control it by keyboard immediately
      player.focus();
      // Do not trigger fullscreen native player on mobile
      player.playsinline(true);

      playerControllerRef.current = BrightCoveControllerFactory(
        player,
        player.$('video'),
        isLivePlayer,
        accessToken,
      );
      events?.onPlayerLoaded?.(playerControllerRef.current);
      initPlayerEvents();
    })();

    // Waiting until player script and playbackMeta all loaded
    (async () => {
      const pendedMeta = await MetaLoader(playerLoader, playbackMetaResolve.current);
      playerControllerRef.current?.loadMeta?.(pendedMeta, startPosition);
    })();

    return () => {
      disposeEvents();
      playerRef.current?.reset();
      playerRef.current = null;
      events?.onPlayerUnload?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPosition]);

  useEffect(() => {
    if (youboraPlayerConfig && youboraPlayerConfig?.content_id && playerRef.current) {
      youboraPlugin.setOptions(youboraPlayerConfig?.getOptions());
      // youbora has the annoying habit to overwrite content.id before sending the start event, hence we have to set data-video-id here.
      playerRef.current.options({
        'data-video-id': youboraPlayerConfig.getOptions()['content.id'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youboraPlayerConfig, playerRef.current]);

  // Load the source url when we have one.
  useEffect(() => {
    const { current: player } = playerRef;
    if (!playbackMeta || !playbackMeta.playbackSource) return;

    if (player) {
      // If player has been initialized
      playerControllerRef.current?.loadMeta?.(playbackMeta, startPosition);
    } else {
      // If player still loading, add into async racing
      playbackMetaResolve.current?.load(playbackMeta);
    }
  }, [playbackMeta, startPosition]);

  useEffect(() => {
    return () => {
      youboraPlugin._adapter?.stop();
      // Youbora is not catching the stop event from
      // Brightcove when we click on the back arrow button, so we are manually sending the event.
      // Without that we see an interrupted series of ping requests to youbora until you play something else.
      youboraPlugin._adapter?.fireStop();
    };
  }, []);

  return <div data-testid="brightcove-player" ref={videoWrapperRef} className={className} />;
};

export default BrightCovePlayer;
