import { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { isNil } from 'ramda';

import {
  LinearSlot,
  LinearChannelGroup,
  LinearChannel,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { videoContentStarted, videoContentStopped } from '@/Analytics/Segment';
import { selectors, actions, utils } from '@/Store';
import sessionId from '@/Utils/SessionId';
import usePersistCallback from '@/Hooks/usePersistCallback';

import { VideoEventType } from '@/Analytics/Video';
import dayjs from 'dayjs';

import { PLAYER_MODE } from '@/Utils/PageLocation';
import useParentalForbidden from '../ParentalControlPanel/useParentalForbidden';
import { DRM_TYPE } from '../DRM';

const captureVideoContentEvent = (
  type: VideoEventType,
  position: number,
  origin: string,
  slot?: LinearSlot | null,
  selectedChannelRef?: LinearChannel | null,
  selectedCategoryRef?: LinearChannelGroup | null,
) => {
  if (slot && selectedCategoryRef && selectedChannelRef) {
    const props = {
      content_id: slot?.programme?.id,
      channel: utils.analytics.getSegmentSlotChannel(selectedChannelRef),
      genre: utils.analytics.getSegmentSlotGenres(slot),
      type: utils.analytics.getContentTypeBySlot(slot),
      title: utils.analytics.getSegmentSlotTitle(slot),
      platform: 'Web',
      playback: 'Linear',
      episode: utils.analytics.getSlotEpisodeNumber(slot, utils.slot.isSport(slot)),
      season: utils.analytics.getSlotSeasonNumber(slot),
      position: Math.floor(position),
      duration: dayjs(slot?.end).diff(slot?.start, 'second'),
      origin,
      livestream: slot?.live,
    };

    switch (type) {
      case VideoEventType.Started: {
        videoContentStarted(props);
        break;
      }
      case VideoEventType.Stopped: {
        videoContentStopped(props);
        break;
      }

      default: {
        break;
      }
    }
  }
};

const useLiveData = (
  providedChannelId?: string,
  isParentalRestrictionResolved?: boolean,
  playerMode?: string,
  startPosition?: string,
  origin?: string,
) => {
  const dispatch = useDispatch();

  // Channel
  const mediaAssetId = useSelector(selectors.channels.selectedChannelId);
  const selectedChannel = useSelector(selectors.channels.selectedChannel);
  const selectedChannelIsLoading = useSelector(selectors.channels.selectedChannelSlotIsLoading);

  const playbackMeta = useSelector(selectors.playback.getPlaybackMeta)(mediaAssetId || '');
  const playbackMetaError = useSelector(selectors.playback.playbackMetaError);
  const isConcurrentLimitReached = useSelector(selectors.playback.isConcurrentPlaybackError)(
    mediaAssetId || '',
  );
  // Playing channel slot info
  const currentSlot = useSelector(selectors.channels.selectedChannelSlot);
  const selectedLinearSlot = useSelector(selectors.channels.selectedSlot);
  const selectedSlotIsLoading = useSelector(selectors.channels.selectedChannelSlotIsLoading);

  // Section categories list
  const selectedCategoryId = useSelector(selectors.channels.selectedCategoryId);
  const selectedCategory = useSelector(selectors.channels.selectedCategory);
  const categories = useSelector(selectors.channels.categories);
  const categoriesError = useSelector(selectors.channels.categoriesError);

  // Channels list for category
  const getChannels = useSelector(selectors.channels.getChannelsByCategoryId);
  const channels = getChannels(selectedCategoryId);
  const channelsIsLoading = useSelector(selectors.channels.channelsByCategoryIdIsLoading);
  const channelsByCategoryIdError = useSelector(selectors.channels.channelsByCategoryIdError);

  const isPlaybackError = channelsByCategoryIdError || categoriesError || playbackMetaError;

  const firstChannelId = channels?.[0]?.id;

  // capture the previous content when switching slots
  const previousCategory = usePrevious<LinearChannelGroup | null>(selectedCategory);
  const previousChannel = usePrevious<LinearChannel | null>(selectedChannel);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const previousIsFirstLoad = usePrevious(isFirstLoad);

  // Status of if a channel been selected in Live Player
  const hasSelectedChannelRef = useRef(false);

  const previousSlot = usePrevious<LinearSlot | null | undefined>(currentSlot);

  // Parental forbidden flag
  const isParentalForbidden = useParentalForbidden(currentSlot, isParentalRestrictionResolved);

  const clearChannelPlaybackMeta = usePersistCallback((shouldStop = true) => {
    if (!mediaAssetId) {
      return;
    }
    if (shouldStop) {
      dispatch(actions.playback.stopPlayback(mediaAssetId, true, sessionId));
    }
    dispatch(actions.playback.removePlaybackMeta(mediaAssetId));
    dispatch(actions.channels.clearSelectedChannelSlot());
  });

  const handleSelectCategory = useCallback(
    categoryId => {
      dispatch(actions.channels.setSelectedCategoryId(categoryId));
    },
    [dispatch],
  );

  const handleSelectChannel = usePersistCallback((channelId, isFirst = false) => {
    if (!isFirst) {
      clearChannelPlaybackMeta();
    }
    if (channelId) {
      hasSelectedChannelRef.current = true;
      dispatch(actions.channels.changeChannel(channelId));
      // Only first channelSlots will fetch the whole channel detail
      if (playerMode === PLAYER_MODE.linearSlot && !selectedLinearSlot) {
        const offset = startPosition ? dayjs().diff(dayjs(startPosition), 's') : 0;
        dispatch(actions.channels.fetchSelectedChannelSlot(channelId, offset, isFirst));
      } else if (playerMode !== PLAYER_MODE.linearSlot) {
        dispatch(actions.channels.fetchSelectedChannelSlot(channelId, 0, isFirst));
      }
      // This env was added to determine if the channel was selected from the channel drawer
      // or is the default channel selected whenever the player is initialized
      // This setting has influence on the analytics to determine the player referrer
      setIsFirstLoad(isFirst);
    }
  });

  // Load the playbackMeta always from url params of LiveTV page
  useEffect(() => {
    // If initialized OR has parental restriction and has no Pin resolved yet
    if (isParentalForbidden === false && providedChannelId) {
      // && mediaAssetId === providedChannelId
      dispatch(actions.playback.fetchPlaybackMeta(providedChannelId, true, DRM_TYPE, sessionId));
    }
  }, [providedChannelId, isParentalForbidden, dispatch]);

  const onPlaybackRetry = usePersistCallback(() => {
    if (providedChannelId && !selectedChannelIsLoading) {
      dispatch(actions.playback.fetchPlaybackMeta(providedChannelId, true, DRM_TYPE, sessionId));
    }
  });

  // Load all the categories & channels data
  useEffect(() => {
    // If has make the initial categories loading
    if (selectedChannel || hasSelectedChannelRef.current) {
      return;
    }
    dispatch(actions.channels.fetchCategories());
  }, [dispatch, selectedChannel]);

  // Once the first channels has been loaded, and there is no playbackMeta loaded yet
  useEffect(() => {
    if (hasSelectedChannelRef.current) {
      return;
    }

    let channelId = '';
    // If live-tv page has provide a channel id
    if (providedChannelId) {
      channelId = providedChannelId;
    }
    // If has selected a channel previously
    else if (mediaAssetId) {
      channelId = mediaAssetId;
    }
    // First time enter live-tv without loading the channels
    else if (firstChannelId) {
      channelId = firstChannelId;
    }
    handleSelectChannel(channelId, true);
  }, [providedChannelId, mediaAssetId, firstChannelId, handleSelectChannel]);

  // Once categories be loaded in first time
  useEffect(() => {
    // select first one as default section category
    if (!selectedCategoryId) handleSelectCategory(categories?.[0]?.id || '');
  }, [categories, selectedCategoryId, handleSelectCategory]);

  const sendVideoContentEvent = usePersistCallback(
    (type: VideoEventType, position: number, slot?: LinearSlot | null) => {
      const firstLoad = type === VideoEventType.Stopped ? previousIsFirstLoad : isFirstLoad;
      const videoOrigin = isNil(origin) ? 'External' : origin;
      captureVideoContentEvent(
        type,
        position,
        firstLoad ? videoOrigin : 'Player',
        slot,
        previousChannel,
        previousCategory,
      );
    },
  );

  return {
    selectedCategoryId,
    selectedChannel,
    isFirstLoad,
    playbackMeta,
    isConcurrentLimitReached,
    onPlaybackRetry,
    mediaAssetId,
    isPlaybackError,
    selectedSlotIsLoading,
    currentSlot,
    categories,
    channels,
    channelsIsLoading,
    isParentalForbidden,
    clearChannelPlaybackMeta,
    handleSelectCategory,
    handleSelectChannel,
    sendVideoContentEvent,
    previousSlot,
  };
};

export default useLiveData;
