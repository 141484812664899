import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useGesture } from 'react-use-gesture';
import { usePrevious } from 'react-use';
import Icon from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { ReactComponent as VolumeMax } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_volume_max.svg';
import { ReactComponent as VolumeMed } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_volume_med.svg';
import { ReactComponent as VolumeMin } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_volume_min.svg';
import { ReactComponent as VolumeMute } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_volume_mute.svg';

import { numberIn0To1 } from '@/Utils/NumberInRange';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;
  isDisabled?: boolean;
  onActiveChange: (active: boolean) => void;

  isMuted: boolean;
  onIsMutedChange: (newIsMuted: boolean) => void;
  volume: number;
  onVolumeChange: (newVolume: number) => void;
}

const VolumePanel: FC<Props> = ({
  isDisabled = false,
  onActiveChange,
  isMuted,
  onIsMutedChange,
  volume,
  onVolumeChange,
}) => {
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const [volumeLocal, setVolumeLocal] = useState<number>(volume);
  const volumeBarHeightRef = useRef<number>(NaN);
  const volumeAtDragStartRef = useRef<number>(volumeLocal);
  const [isVolumeBarDragging, setIsVolumeBarDragging] = useState(false);

  const [isVolumeControlHovering, setIsVolumeControlHovering] = useState(false);
  const isVolumeControlActive = isVolumeBarDragging || isVolumeControlHovering;
  const isVolumeControlActivePrev = usePrevious(isVolumeControlActive);

  const setIsVolumeControlHoveringDebounce = useCallback(
    debounce(setIsVolumeControlHovering, 400),
    [],
  );

  useEffect(() => {
    if (isVolumeControlActive !== isVolumeControlActivePrev) {
      onActiveChange(isVolumeControlActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVolumeControlActive, onActiveChange]);

  const setVolume = useCallback(
    (newVolume: number) => {
      setVolumeLocal(newVolume);
      onVolumeChange(newVolume);
    },
    [onVolumeChange],
  );

  const bindVolumeControlHover = useGesture({
    onHover: ({ hovering }) => {
      if (hovering) {
        setIsVolumeControlHoveringDebounce.cancel();
        setIsVolumeControlHovering(true);
      } else {
        setIsVolumeControlHoveringDebounce(false);
      }
    },
  });

  useEffect(() => {
    return () => {
      setIsVolumeControlHoveringDebounce.cancel();
    };
  }, [setIsVolumeControlHoveringDebounce]);

  const bindVolumeDrag = useGesture({
    onClick: event => {
      if (event) {
        event.stopPropagation();
      }
    },
    onDragStart: ({ event, xy: [, y] }) => {
      setIsVolumeBarDragging(true);

      if (!event) {
        return;
      }

      event.stopPropagation();

      const { top } = event.currentTarget.getBoundingClientRect();
      const volumeBarStyles = window.getComputedStyle(event.currentTarget);
      const height = parseFloat(volumeBarStyles.height);
      const paddingTop = parseFloat(volumeBarStyles.paddingTop);
      const paddingBottom = parseFloat(volumeBarStyles.paddingBottom);
      const barHeight = height - paddingTop - paddingBottom;
      volumeBarHeightRef.current = barHeight;

      const newVolume = numberIn0To1(1 - (y - (top + paddingTop)) / barHeight);
      volumeAtDragStartRef.current = newVolume;
      setVolume(newVolume);
    },
    onDragEnd: ({ event }) => {
      if (event) {
        event.stopPropagation();
      }
      setIsVolumeBarDragging(false);
    },
    onDrag: ({ event, movement: [, my] }) => {
      if (event) {
        event.stopPropagation();
      }
      // Calculate new volume
      const { current: volumeBarHeight } = volumeBarHeightRef;
      const { current: volumeAtDragStart } = volumeAtDragStartRef;
      const changed = (my * -1) / volumeBarHeight;
      const newVolume = numberIn0To1(volumeAtDragStart + changed);
      setVolume(newVolume);
    },
  });

  useEffect(() => {
    setVolumeLocal(volume);
  }, [volume]);

  let VolumeIcon;
  if (isMuted || volume === 0) {
    VolumeIcon = VolumeMute;
  } else if (volume > 0 && volume <= 1 / 3) {
    VolumeIcon = VolumeMin;
  } else if (volume > 1 / 3 && volume <= 2 / 3) {
    VolumeIcon = VolumeMed;
  } else if (volume > 2 / 3 && volume <= 1) {
    VolumeIcon = VolumeMax;
  }

  return (
    <div
      className={classnames(styles.volumePanel, {
        [styles.volumeControlActive]: !isDisabled && isVolumeControlActive,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...bindVolumeControlHover()}
    >
      <button
        type="button"
        className={classnames(styles.controlButton, styles.volumeBtn)}
        onClick={() => onIsMutedChange(!isMuted)}
        title={`${isMuted ? 'Unmute' : 'Mute'} (m)`}
        data-testid="video-player-volume-mute-unmute"
        name="Player volume mute/unmute button"
      >
        <Icon className={styles.buttonIcon} component={VolumeIcon} />
      </button>
      <div className={styles.volumeBarWrapper}>
        <div
          className={classnames(styles.volumeBar)}
          ref={volumeBarRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...bindVolumeDrag()}
        >
          <div className={styles.volumeBarOuter}>
            <div
              className={styles.volumeBarInner}
              style={{ height: `${isMuted ? 0 : volumeLocal * 100}%` }}
            />
            <div
              className={styles.volumeHandle}
              style={{ bottom: `${isMuted ? 0 : volumeLocal * 100}%` }}
              data-testid="video-player-volume-handle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolumePanel;
